import {
  LanguageWritten,
  LocalTimeOfDay,
  MicroDateTime,
  NaiveDateTime,
  WTimeZone,
} from '@kjt01/greendot-wasm'
import dayjs from 'dayjs'

export const getDate = (timestamp: MicroDateTime | NaiveDateTime | null) => {
  const date = timestamp ? new Date(timestamp) : undefined
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(date)
}

export const getTime = (timestamp?: MicroDateTime | NaiveDateTime | null) => {
  const date = timestamp ? new Date(timestamp) : undefined

  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(date)
    .replaceAll(' ', '')
}

export const toMinutes = (timestamp: LocalTimeOfDay | null | undefined) => {
  if (!timestamp) return 0

  const parts = timestamp.split(':')

  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)

  return hours * 60 + minutes
}

export const fromMinutes = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
    .toLocaleString()
    .padStart(2, '0')

  const remainingMinutes = (minutes % 60).toLocaleString().padStart(2, '0')

  return `${hours}:${remainingMinutes}:00.000000`
}

export const formatMicroDateTime = (dateTime: dayjs.Dayjs) => {
  return `${dateTime.format(
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'YYYY-MM-DD HH:mm:ss',
  )}.000000Z` as MicroDateTime
}

export const formatNaiveDateTime = (dateTime: dayjs.Dayjs) => {
  return `${dateTime.format(
    // eslint-disable-next-line lingui/no-unlocalized-strings
    'YYYY-MM-DD HH:mm:ss',
  )}.000000` as NaiveDateTime
}

export const currentDateTime = dayjs()
// eslint-disable-next-line lingui/no-unlocalized-strings
export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
// eslint-disable-next-line lingui/no-unlocalized-strings
export const microTimeSpanFormat = 'HH:mm:ss.SSSSSS'

export const getTimeZoneDateTime = ({
  timestamp,
  timeZone,
}: {
  timestamp: Date | null | undefined
  timeZone: string | undefined
}) => {
  const time = timestamp ?? Date.now()

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timeZone,
  }).format(time)
}

export const formatTime = ({
  timestamp,
  timeZone,
  hour12 = true,
}: {
  timestamp: MicroDateTime | NaiveDateTime | null
  timeZone?: WTimeZone | null
  hour12?: boolean
}): string | null => {
  if (timestamp == null) return null

  const date = new Date(timestamp)
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
    timeZone: timeZone ?? undefined,
    hour12,
  })
  return formatter.format(date)
}

export const formatDateWithSuffix = (date: string) => {
  const numberDate = parseInt(date)

  if (numberDate > 3 && numberDate < 21) {
    return `${numberDate}th`
  }

  switch (numberDate % 10) {
    case 1:
      return `${numberDate}st`
    case 2:
      return `${numberDate}nd`
    case 3:
      return `${numberDate}rd`
    default:
      return `${numberDate}th`
  }
}

export const formatLocalTimeOfDayDateTime = ({
  time,
  locale,
}: {
  time: LocalTimeOfDay | null
  locale: LanguageWritten
}) => {
  const t = time?.split('.')[0]
  const [hours, minutes] = t?.split(':') ?? ['00', '00']

  return new Intl.DateTimeFormat(locale === 'en' ? 'en-US' : 'zh-Hans-CN', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(
    time ? new Date(0, 0, 0, parseInt(hours), parseInt(minutes)) : undefined,
  )
}

export const DAYS_OF_WEEK = [
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Sunday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Monday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Tuesday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Wednesday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Thursday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Friday',
  // eslint-disable-next-line lingui/no-unlocalized-strings
  'Saturday',
]
