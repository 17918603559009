import { CircularProgress } from '@mui/material'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'

const Button = ({
  isDisabled,
  onClick,
  isSubmitting,
  variant = 'primary',
  size = 'default',
  cssProps,
  children,
}: {
  isDisabled?: boolean
  onClick?: () => void
  isSubmitting?: boolean
  variant?: 'primary' | 'blue' | 'gray'
  size?: 'default' | 'small'
  cssProps?: React.CSSProperties
  children: React.ReactNode
}) => {
  const { typography } = useTypographyContext()

  return (
    <button
      disabled={isDisabled}
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingInline: spacing.normal,
        paddingBlock: size === 'default' ? spacing.normal : spacing.base,
        borderRadius: constants.borderRadius.medium,
        ...variants[variant],
        ...typography.button.large,
        ...cssProps,
      }}
      onClick={onClick}
    >
      {isSubmitting ? (
        <CircularProgress
          sx={{ color: colors.neutrals[500] }}
          size={typography.button.large.lineHeight}
        />
      ) : (
        children
      )}
    </button>
  )
}

export default Button

/* eslint-disable lingui/no-unlocalized-strings */
const variants = {
  primary: {
    background: colors.white[100],
    border: `1px solid ${colors.primary2[500]}`,
    ':disabled': {
      background: colors.neutrals[25],
      borderColor: colors.white[600],
      color: colors.neutrals[100],
      cursor: 'not-allowed',
    },
    ':hover:enabled': {
      background: colors.primary2[50],
      cursor: 'pointer',
    },
    ':active:enabled': {
      color: colors.neutrals[50],
      background: colors.primary2[500],
    },
    color: colors.primary2[500],
  },
  blue: {
    background: colors.primary2[500],
    border: `1px solid ${colors.primary2[500]}`,
    ':disabled': {
      background: colors.neutrals[25],
      borderColor: colors.neutrals[25],
      color: colors.neutrals[200],
      cursor: 'not-allowed',
    },
    ':hover:enabled': {
      background: colors.primary2[300],
      cursor: 'pointer',
    },
    ':active:enabled': {
      color: colors.neutrals[50],
      background: colors.primary2[800],
      borderColor: colors.primary2[800],
    },
    color: colors.white[500],
  },
  gray: {
    background: colors.white[500],
    border: `1px solid ${colors.neutrals[50]}`,
    ':disabled': {
      background: colors.neutrals[25],
      borderColor: colors.neutrals[25],
      color: colors.neutrals[200],
      cursor: 'not-allowed',
    },
    ':hover:enabled': {
      background: colors.primary2[50],
      borderColor: colors.primary2[100],
      cursor: 'pointer',
    },
    ':active:enabled': {
      color: colors.white[50],
      background: colors.primary2[700],
      borderColor: colors.primary2[700],
    },
    color: colors.neutrals[500],
  },
}
