import Big from 'big.js'
import { MinorCurrency } from '@kjt01/greendot-wasm'

export const getUsd = (
  price: string | number | undefined | null,
  currencySign?: 'accounting',
) => {
  if (price == null) return null

  if (typeof price === 'string' && price.includes('mu')) {
    price = parseCurrency(price as MinorCurrency)
  }

  if (typeof price === 'string') price = parseFloat(price)

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign,
  }).format(price)
}

export const parseCurrency = (amount: MinorCurrency) => {
  const parts = amount.split('mu ')
  return parseInt(parts[0], 10) / 100
}

export const serializeCurrency = (
  amount: number = 0,
  currency: string = 'USD',
): MinorCurrency => {
  const minorCurrency = amount * 100
  return `${parseInt(minorCurrency.toFixed(0))}mu ${currency}`
}

export const usdToMinorCurrency = (usd: string | number): MinorCurrency => {
  if (typeof usd === 'string') usd = parseFloat(usd.replace(/[^\d.-]/g, ''))
  // eslint-disable-next-line lingui/no-unlocalized-strings
  return `${new Big(usd).times(100).toNumber()}mu USD`
}

export const isMinorCurrency = (value: unknown): value is MinorCurrency => {
  return typeof value === 'string' && value.includes('mu')
}
